import { Link } from '@remix-run/react'
import { ChevronDown } from 'lucide-react'

import { BrandMenu } from './brand-menu.tsx'

export function Footer() {
	return (
		<footer className="border-t border-border bg-gray-50 pb-16 pt-8 dark:bg-background">
			<div className="mx-auto max-w-6xl px-6 sm:px-6 lg:px-8">
				<div className="grid grid-cols-2 gap-12 sm:grid-cols-3 lg:mt-20 lg:grid-cols-5">
					<div className="col-span-2 sm:col-span-3 lg:col-span-1">
						<Link
							prefetch="intent"
							className="flex items-center gap-1"
							to="/"
							aria-current="page"
						>
							<img
								className="aspect-square h-10 w-10"
								src="/img/logo.webp"
								alt="Logo carregados."
							/>
							<span>| carregados</span>
						</Link>{' '}
						<p className="mt-8 text-base font-normal text-muted-foreground">
							Marketplace de carros elétricos no Brasil.{' '}
						</p>{' '}
						<p className="mt-4 text-sm font-normal text-muted-foreground">
							© {new Date().getFullYear()}
						</p>
						<div className="mt-4">
							<div className="flex flex-col gap-1 text-sm font-normal text-muted-foreground">
								<div className="flex items-center gap-1">
									Atendimento
									<ChevronDown size={14} />
								</div>
								<p className="text-sm font-normal text-primary hover:underline">
									<a href="mailto:contato@carregados.com.br">
										contato@carregados.com.br
									</a>
								</p>
							</div>
							<div className="flex items-center gap-1 pt-6 text-sm font-normal text-muted-foreground">
								<a
									href="https://x.com/dnz"
									target="_blank"
									rel="noopener noreferrer"
									className="flex items-center py-1 gap-2 rounded-full bg-black px-4 text-white hover:bg-gray-900"
								>
									<svg viewBox="0 0 24 24" className="h-4 w-4 fill-current">
										<path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
									</svg>
								</a>
							</div >
						</div>
					</div>{' '}
					<div className="lg:pl-12">
						<p className="text-primary-500 text-xs font-semibold uppercase tracking-widest">
							Links
						</p>{' '}
						<ul className="mt-8 space-y-5">
							<li>
								<Link
									prefetch="intent"
									to="/carros"
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Carros
								</Link>
							</li>
							<li>
								<Link
									prefetch="intent"
									to="/estacoes"
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Estações de Carregamento
								</Link>
							</li>
							<li>
								<Link
									prefetch="intent"
									to="/mapa"
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Mapa de Carregadores
								</Link>
							</li>
							<li>
								<Link
									prefetch="intent"
									to="/adicionar-estacao"
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Adicionar Estação
								</Link>
							</li>
							<li>
								<Link
									prefetch="intent"
									to="/mapa?planejar=viagem"
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Planejar Viagem
								</Link>
							</li>
							<li>
								<Link
									prefetch="intent"
									to="/#preco"
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Anunciar Carro
								</Link>
							</li>
							<li>
								<Link
									prefetch="intent"
									to="/dados-sobre-o-mercado-eletrico"
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Dados Sobre o Mercado de Carros Elétricos
								</Link>
							</li>
							<li>
								<Link
									prefetch="intent"
									to="/#utilidades"
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Utilidades
								</Link>
							</li>
						</ul>
					</div>{' '}
					<div className="lg:pl-12">
						<p className="text-primary-500 text-xs font-semibold uppercase tracking-widest">
							Políticas
						</p>{' '}
						<ul className="mt-8 space-y-5">
							<li>
								<Link
									to="/termos-e-condicoes"
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
									title=""
								>
									Termos e Condições
								</Link>
							</li>
							<li>
								<Link
									to="/politica-de-privacidade"
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
									title=""
								>
									Política de Privacidade
								</Link>
							</li>
							<li>
								<Link
									to="/remover-estacao"
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
									title=""
								>
									Solicitar Remoção de Estação
								</Link>
							</li>
						</ul>
					</div>{' '}
					<div className="lg:pl-12">
						<p className="text-primary-500 text-xs font-semibold uppercase tracking-widest">
							Por Estado
						</p>{' '}
						<ul className="mt-8 space-y-5">
							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('acre (ac)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Acre
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('alagoas (al)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Alagoas
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('amapá (ap)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Amapá
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('amazonas (am)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Amazonas
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('bahia (ba)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Bahia
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('ceará (ce)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Ceará
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent(
										'distrito federal (df)',
									)}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Distrito Federal
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent(
										'espírito santo (es)',
									)}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Espírito Santo
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('goiás (go)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Goiás
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('maranhão (ma)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Maranhão
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent(
										'mato grosso (mt)',
									)}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Mato Grosso
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent(
										'mato grosso do sul (ms)',
									)}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Mato Grosso do Sul
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent(
										'minas gerais (mg)',
									)}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Minas Gerais
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('pará (pa)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Pará
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('paraíba (pb)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Paraíba
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('paraná (pr)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Paraná
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('pernambuco (pe)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Pernambuco
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('piauí (pi)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Piauí
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent(
										'rio de janeiro (rj)',
									)}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Rio de Janeiro
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent(
										'rio grande do norte (rn)',
									)}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Rio Grande do Norte
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent(
										'rio grande do sul (rs)',
									)}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Rio Grande do Sul
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('rondônia (ro)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Rondônia
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('roraima (rr)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Roraima
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent(
										'santa catarina (sc)',
									)}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Santa Catarina
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('são paulo (sp)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									São Paulo
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('sergipe (se)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Sergipe
								</Link>
							</li>

							<li>
								<Link
									prefetch="intent"
									to={`/carros?estado=${encodeURIComponent('tocantins (to)')}`}
									className="inline-flex text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
								>
									Tocantins
								</Link>
							</li>
						</ul>
					</div>{' '}
					<div className="lg:pl-12">
						<p className="text-primary-500 text-xs font-semibold uppercase tracking-widest">
							Por Marca
						</p>{' '}
						<BrandMenu />
					</div>
				</div>
			</div>
		</footer>
	)
}
